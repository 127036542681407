import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import ClimateSurveyChart from './chart.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Each spring, PSI sends out a climate survey to parents, students in Grades 3 to 12, and faculty and staff to seek their opinions on a wide range of school areas.  We use these responses  to gain a wide perspective that helps us to identify areas of strength and areas of growth for the school.  This year, we found an unusually high percentage of constituents responded with “I do not know” to many of the  questions, which impacted the results.  The data we present below was acquired over a period of 3 years; prior to the pandemic, early in the pandemic and after about ten weeks of government-mandated distance learning, and this year, after 12 months’ experience with the pandemic; with some learning in blended mode, and some weeks of remote learning.  Included `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/1KdnPHQHPfxouaI2cpi1dnO7epyFbXi27/view"
      }}>{`here`}</a>{` are representative questions with responses, interpretations with planned actions and example survey comments.  Members of the PSI community can access a password-protected PDF with full results to the climate survey here (password can be found in the Weekly Digest published on Friday 10 September).`}</p>
    <ClimateSurveyChart mdxType="ClimateSurveyChart" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      